@charset "utf-8";



@import "minimal-mistakes/skins/dirt"; // skin
@import "minimal-mistakes"; // main partials

.page__content
{
  font-size: $type-size-6;
}

.blog-image{
	max-height: 300px;
	display:block;
	margin-left:auto;
	margin-right:auto;
}

.blog-image-tall{
	max-height: 600px;
}

.page__hero-image {
  max-width: 50%;
  display:block;
	margin-left:auto;
	margin-right:auto;
}